
import { Component, Inject, ViewEncapsulation, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatColors } from '@fuse/mat-colors';
import { ThemePalette } from '@angular/material/core';
import { ExportService } from '../../../../_services/export.service';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import html2canvas from 'html2canvas'; 
import { DetailFormComponent } from '../../../formulaire/formulaire-details/formulaire-details.component';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
@Component({
    selector: 'appel-remboursement',
    templateUrl: './appel-remboursement.component.html',
    styleUrls: ['./appel-remboursement.component.scss'],
    providers:[ExportService],
    encapsulation: ViewEncapsulation.None
})

export class  GenererAppelRemboursementComponent implements OnInit,AfterViewInit  {
  dialogRef: any;
    @ViewChild("picker", { static: false }) picker: any;
    action: string;
    dialogTitle: string;
    presetColors = MatColors.presets;
    public color: ThemePalette = 'accent';
    rowOpened: string;
    sinistres: any;
    userConnecte = null;
    mail = null;
    detailsPdf: any;
    loaderVisible = false;
    loadData: boolean = false;
    codeRecu: any; //code recu de l'api lors de la connexion 
    codeEnvoye: number=1716; //code envoye par notre menu
    hasGenererAppel: boolean;

    constructor(
        public matDialogRef: MatDialogRef<GenererAppelRemboursementComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _matDialog: MatDialog,
        private exportService: ExportService,
    ) {
        this.action = _data.action;
        this.detailsPdf = _data.pdf;
        this.sinistres = _data.pdf.aRembourser;
        this.appelRemboursementSinistre(this.sinistres)
        if (this.action === 'upload') {
            this.dialogTitle = 'Apercu du fichier appel à remboursement';
            this.detailsPdf = _data.pdf;
            this.mail = _data.mail;
        }
    }
    ngOnInit(): void {
        this.userConnecte = JSON.parse(localStorage.getItem('inclusiveGuarantee'));
        this.codeRecu = JSON.parse(localStorage.getItem('codeRecu'));
        this.checkCode();
    }
    checkCode(){
      this.hasGenererAppel = this.codeRecu.tabIDS.indexOf('0'+(this.codeEnvoye+1)) != -1;
    }
    ngAfterViewInit():void{
      this.captureScreen();
    }

    appelRemboursementSinistre(details): void {
      let nom = "Liste des sinistres";
      let value = [];
      if(details.length!=0){
      for(let val of details){
        let donnees = { prenom:val.assure.prenom ,nom:val.assure.nom , dateNaissance: moment(val.assure.dateNaissance).format('DD/MM/YYYY'),telephone:val.assure.telephone,numeroPiece:val.assure.numeroPiece, dateSouscription: moment(val.dateSouscription).format('DD/MM/YYYY'), datePret: moment(val.datePret).format('DD/MM/YYYY'), dureePret: val.dureePret, montantPret: val.montantPret, montantph1: (val.montantph1 + val.montantph2 + val.montantph3 ),createdAt: moment(val.createdAt).format('DD/MM/YYYY') }
          value.push(donnees);
      }
    }
        if (value.length != 0) {
            var col = ['Prénom', 'Nom', 'Date naissance', 'Téléphone', 'Numéro Piéce', 'Date Souscription', 'Date prêt', 'Durée prêt', 'Montant prêt' , 'Montant indemnisation','Date création'];
            let tabFileBody = ['prenom', 'nom', 'dateNaissance', 'telephone', 'numeroPiece', 'dateSouscription', 'datePret','dureePret','montantPret','montantph1','createdAt'];
            var rows = [];
            var itemCurrent;
            for (var item of value) {
              itemCurrent = item;
              let tabField = [];
              let elementKeys = Object.keys(item);
              let i = 0;
              for (let field of tabFileBody) {
                for (let element of elementKeys) {
                  if (element== field) {
                     tabField.push({ [col[i]]: (itemCurrent[field] || '') });
                  }
                }
                i++;
              }
              rows.push(Object.assign({}, ...tabField));
            }
            this.exportService.exportAsExcelFile(this.exportService.preFormatLoanInfo(rows), nom);
          
        } 
  }
    public captureScreen(){
      var data = document.getElementById('contentToConvert');  
      html2canvas(data).then(canvas =>{  
        this.loadData = true; 
        var imgWidth = 210;   
        var pageHeight = 380;    
        var imgHeight = canvas.height * imgWidth / canvas.width;  
        var heightLeft = imgHeight;  
    
        const contentDataURL = canvas.toDataURL('image/png')  
        let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF  
        var position = 0;  
        pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight) 
        pdf.save('Appel-à-Remboursement.pdf'); // Generated PDF 
     //   pdf.
      });
      this.loadData = false; 
      // if(data){
      //   this.loadData = false;
      this.matDialogRef.close(); 
      // }
     
    }  
 
}
