import { NgModule } from "@angular/core";

import { FuseSidebarComponent } from "./sidebar.component";
import { CommonModule } from "@angular/common";

@NgModule({
  imports: [CommonModule],
  declarations: [FuseSidebarComponent],
  exports: [FuseSidebarComponent]
})
export class FuseSidebarModule {}
