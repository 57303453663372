import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders ,HttpParams} from '@angular/common/http';
import { environment } from 'environments/environment';
import {  HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class RechercheService implements Resolve<any> {
  offset: number;
  max: any;
  constructor(private httpCli: HttpClient) {}

   list(url, offset, max) {
    return this.httpCli.get(environment.apiURL + url+'?max='+max +'&offset=' +offset)
      .pipe(response => response)
  }
  listFormulaire(url) {
    return this.httpCli.get(environment.apiURL + url+'?max='+environment.max +'&offset=' +environment.offset)
        .pipe(response => response)
  }

  addItem (item, url) {
    return this.httpCli.post(environment.apiURL + url, item)
        .pipe(response => response)
  }

  addUserRole (data) {
    return this.httpCli.post(environment.apiURL + 'add-user-to-role', data)
        .pipe(response => response)
  }

  parametrageFichier (data) {
    return this.httpCli.post(environment.apiURL + 'dynamic-create-fichier', data)
        .pipe(response => response)
  }
  addColonne(data) {
    return this.httpCli.post(environment.apiURL + 'colonne-fichiers', data)
        .pipe(response => response)
  }
  listColonnes() {
    return this.httpCli.get(environment.apiURL + 'colonne-fichiers'+'?max='+environment.max +'&offset=' +environment.offset)
        .pipe(response => response)
  }

  updateFormulaireKyc (idFormulaire) {
    return this.httpCli.put(environment.apiURL + 'paramsFichier' ,idFormulaire)
        .pipe(response => response)
  }
  getPartenaireByTypeFichier(data){
    return this.httpCli.post(environment.apiURL + 'get-partenaire-by-type-fichier' ,data)
        .pipe(response => response)
  }
  getFormatFichier(data) {
    return this.httpCli.post(environment.apiURL + 'get-format-fichier' ,data)
        .pipe(response => response)
  }
  checkNomColonne(data){
    return this.httpCli.post(environment.apiURL + 'check-colonne-fichier' ,data)
    .pipe(response => response)
  }

  userByUsername(data){
    return this.httpCli.post(environment.apiURL + 'get-user-by-username' ,data)
    .pipe(response => response)
  }
  edit_Profil(data){
    return this.httpCli.post(environment.apiURL + 'edit_profil' ,data)
    .pipe(response => response)
  }
  uploadFileAssure(data){
    return this.httpCli.post(environment.apiURL + 'file-import' ,data)
    .pipe(response => response)    
  }
  appelCotisationAssure(data){
    return this.httpCli.post(environment.apiURL + 'liste-souscriptions-manuelles' ,data)
    .pipe(response => response)    
  }
  natureFile(data){
    return this.httpCli.post(environment.apiURL + 'valider-import' ,data)
    .pipe(response => response)   
  }
  validerPreuvePaiement(data){
    return this.httpCli.post(environment.apiURL + 'valider-preuve' ,data)
    .pipe(response => response)    
  }
  validerPreuveRemboursement(data){
    return this.httpCli.post(environment.apiURL + 'valider-preuve-remboursement' ,data)
    .pipe(response => response)    
  }
  preuvePaiement(data){
    return this.httpCli.post(environment.apiURL + 'importer-preuve' ,data)
    .pipe(response => response)  
  }

  preuvePaiementremboursement(data){
    return this.httpCli.post(environment.apiURL + 'importer-preuve-remboursement' ,data)
    .pipe(response => response)  
  }
  verifieParams(data){
    return this.httpCli.post(environment.apiURL + 'verifier-params' ,data)
    .pipe(response => response)   
  }
  listFiliales() {
    return this.httpCli.get(environment.apiURL + 'filiales'+'?max='+environment.max +'&offset=' +environment.offset)
        .pipe(response => response)
  }
  reportingAdi() {
    return this.httpCli.get(environment.apiURL + 'reporting-for-adi')
        .pipe(response => response)
  }
  reportingRecolte() {
    return this.httpCli.get(environment.apiURL + 'reporting-for-recolte')
        .pipe(response => response)
  }
  reportingSouscription() {
    return this.httpCli.get(environment.apiURL + 'reporting-autres-souscriptions')
        .pipe(response => response)
  }
  reportingSouscriptionRegion() {
    return this.httpCli.get(environment.apiURL + 'reporting-autres-souscriptions-par-region')
        .pipe(response => response)
  }
  reportingFiliale() {
    return this.httpCli.get(environment.apiURL + 'reporting-filiale')
        .pipe(response => response)
  }
  reportingCommissionForFiliale(){
    return this.httpCli.get(environment.apiURL + 'reporting-commision-filiale')
        .pipe(response => response)
  }
  reportingMontantSouscriptionByZone(){
    return this.httpCli.get(environment.apiURL + 'reporting-souscription-zone')
        .pipe(response => response)
  }
  reportingSouscriptionPartenaire() {
    return this.httpCli.get(environment.apiURL + 'reporting-souscription-partenaire')
        .pipe(response => response)
  }
  reportingSouscriptionZone() {
    return this.httpCli.get(environment.apiURL + 'reporting-souscription-zone')
        .pipe(response => response)
  }

  listTypeFichier() {
    return this.httpCli.get(environment.apiURL + 'type-fichiers'+'?max='+environment.max +'&offset=' +environment.offset)
        .pipe(response => response)
  }
  listFichier() {
    return this.httpCli.get(environment.apiURL + 'fichiers'+'?max='+environment.max +'&offset=' +environment.offset)
        .pipe(response => response)
  }
  listAgences() {
    return this.httpCli.get(environment.apiURL + 'agences'+'?max='+environment.max +'&offset=' +environment.offset)
        .pipe(response => response)
  }
  listPartenaires() {
    return this.httpCli.get(environment.apiURL + 'partenaires'+'?max='+environment.max +'&offset=' +environment.offset)
        .pipe(response => response)
  }
  listCodes() {
    return this.httpCli.get(environment.apiURL + 'code-colonne'+'?max='+environment.max +'&offset=' +environment.offset)
        .pipe(response => response)
  }
  listStatut() {
    return this.httpCli.get(environment.apiURL + 'statuts'+'?max='+environment.max +'&offset=' +environment.offset)
        .pipe(response => response)

  }
  listSpeculation() {
    return this.httpCli.get(environment.apiURL + 'speculations'+'?max='+environment.max +'&offset=' +environment.offset)
        .pipe(response => response)
  } 
  listZone() {
    return this.httpCli.get(environment.apiURL + 'zones'+'?max='+environment.max +'&offset=' +environment.offset)
        .pipe(response => response)
  }
  listProduits() {
    return this.httpCli.get(environment.apiURL + 'produits'+'?max='+environment.max +'&offset=' +environment.offset)
        .pipe(response => response)
  }
  listeUser() {
    return this.httpCli.get(environment.apiURL + 'users'+'?max='+environment.max +'&offset=' +environment.offset)
  }
  updateItem (item, id, url) {
    return this.httpCli.put(environment.apiURL + url + '/' + id, item)
        .pipe(response => response)
  }

  deleteItem (item, url) {
    return this.httpCli.delete(environment.apiURL + url + '/' + item, item)
        .pipe(response => response)
  }
  rechercheAppelCotisation(uuid){
    return this.httpCli.post(environment.apiURL+'get-appel-cotisation-by-uuid',uuid)
  }
  rechercheAppelRemboursement(uuid){
    const params = new HttpParams()
    .set('uuid',uuid)
    return this.httpCli.get(environment.apiURL+'get-appel-remboursement-by-uuid',{params})
  }
  doRechercher(data,url) {
    if(data.isGlobal){
    const params = new HttpParams()
    .set('isGlobal',data.isGlobal)
    .set('searchQuery', data.searchQuery)
    return this.httpCli.get(environment.apiURL +url,{params})
    .pipe(response => response)
  }else{
   let keys =Object.keys(data.searchQuery);
   let newMap = {};
   for(let key of keys){
      if(data.searchQuery[key]==''){
        delete data.searchQuery[key];
      }else{
        newMap= {...newMap,...{[key]:data.searchQuery[key]}};
      }
    }
    return this.httpCli.get(environment.apiURL +url+'?isGlobal='+data.isGlobal+'&searchQuery='+encodeURIComponent(JSON.stringify(newMap)))
    .pipe(response => response)
  }
}
doRechercherSinistre(id,type) {
  return this.httpCli.get(environment.apiURL +"sinistres"+'?'+type+'='+id)
  .pipe(response => response)
}

doRechercher1(data,url) {
  if(data.isGlobal){
  const params = new HttpParams()
  .set('isGlobal',data.isGlobal)
  .set('searchQuery', data.searchQuery)
  return this.httpCli.get(environment.apiURL +url,{params})
  .pipe(response => response)
}else{
 let keys =Object.keys(data.searchQuery);
 let newMap = {};
 for(let key of keys){
    if(data.searchQuery[key]==null){
      delete data.searchQuery[key];
    }else{
      newMap= {...newMap,...{[key]:data.searchQuery[key]}};
    }
  }
  return this.httpCli.get(environment.apiURL +url+'?isGlobal='+data.isGlobal+'&searchQuery='+encodeURIComponent(JSON.stringify(newMap)))
  .pipe(response => response)
}
}
  getListItemByCodeItem (code,url) {
    return this.httpCli.get(environment.apiURL + url+code)
  }
  activateDesactivate(item,url){
    return this.httpCli.put(environment.apiURL+url+'/'+item.id, item)
    .pipe(response => response)
    }
    getAllCountrie(item,url){
      return this.httpCli.put(environment.apiURL+url+'/'+item.id, item)
      .pipe(response => response)
    }

  getAllStatistiques(){
      return this.httpCli.get(environment.apiURL+'reporting')
      .pipe(response => response)
    }
    getStatistiquesSinistre(){
      return this.httpCli.get(environment.apiURL+'reporting-sinistres')
      .pipe(response => response)
    }
    PartenaireByFiliale(data){
      return this.httpCli.post(environment.apiURL+'get-partenaire-by-filiale',data)
      .pipe(response => response)
    }
    getStatistiquesAssures(){
      return this.httpCli.get(environment.apiURL+'reporting-assures')
      .pipe(response => response)
    }
    getStatistiquesAssureByPartenaire(){
      return this.httpCli.get(environment.apiURL+'reporting-assures-par-partenaire')
      .pipe(response => response)
    }
    getStatistiquesFichierByPartenaire(){
      return this.httpCli.get(environment.apiURL+'reporting-fichiers-par-partenaire')
      .pipe(response => response)
    }
    getStatistiquesSinistreByPartenaire(){
      return this.httpCli.get(environment.apiURL+'reporting-sinistres-partenaires')
      .pipe(response => response)
    }
    getStatistiquesAppelByPartenaire(){
      return this.httpCli.get(environment.apiURL+'reporting-appel-partenaire')
      .pipe(response => response)
    }
    getStatistiquesFichierImporteByPartenaire(){
      return this.httpCli.get(environment.apiURL+'reporting-fichiers-importes-par-partenaire')
      .pipe(response => response)
    }
    getStatistiquesAssureur(){
      return this.httpCli.get(environment.apiURL+'nb-asureurs')
      .pipe(response => response)
    }
    getStatistiquesPartenaires(){
      return this.httpCli.get(environment.apiURL+'nb-partenaires')
      .pipe(response => response)
    }
    getStatistiquesCotisations(){
      return this.httpCli.get(environment.apiURL+'reporting-cotisations')
      .pipe(response => response)
    }

    reportingAssuresForAssureurs() {
      return this.httpCli.get(environment.apiURL + 'reporting-assures-for-assureurs')
          .pipe(response => response)
    }
    reportingSinistresForAssureurs() {
      return this.httpCli.get(environment.apiURL + 'reporting-sinistres-assureurs')
          .pipe(response => response)
    }
    reportingnbFichiersImportesForAssureur(){
      return this.httpCli.get(environment.apiURL + 'reporting-nb-fichiers-importes-par-assureur')
          .pipe(response => response)
    }
    reportingFichiersImportesFAssureur(){
      return this.httpCli.get(environment.apiURL + 'reporting-fichiers-importes-par-assureur')
          .pipe(response => response)
    }
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {}
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('Une erreur rencontre:', error.error.message);
    } else {
      console.error(
        `Le serveur a retourne le code ${error.status}, ` +
        `Message: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(error.error);
  };
}
