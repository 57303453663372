import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';

import { FuseSharedModule } from '@fuse/shared.module';
import { PdfComponent } from './pdf.component';
import { UploadPdfComponent } from '../administration/fichier-assure/upload-pdf/upload-pdf.component';
import { GenererAppelRemboursementComponent } from '../administration/sinistre/sinistre-importer/appel-remboursement/appel-remboursement.component';
import { MatToolbar, MatToolbarModule, MatTabsModule } from '@angular/material';
import { MaterialUiModule } from '../material-ui/material-ui.module';
import { AdministrationModule } from '../administration/administration.module';


const routes = [
    {
        path     : 'pdf',
        component: PdfComponent
    },
    {
        path: 'generer-appel-cotisation/:id',
        component: PdfComponent,
      },
      {
        path: 'generer-appel-remboursement/:uuid',
        component: PdfComponent,
      }
];

@NgModule({
    declarations: [
        PdfComponent,
        UploadPdfComponent,
        GenererAppelRemboursementComponent
    ],
    imports     : [
        RouterModule.forChild(routes),

        MatIconModule,
        MatToolbarModule,
        MatTabsModule,
        MaterialUiModule,
      //  AdministrationModule.forChild(UploadPdfComponent),
        FuseSharedModule
    ],entryComponents:[
        UploadPdfComponent,
        GenererAppelRemboursementComponent
    ]
})
export class PdfModule
{
}
