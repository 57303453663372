import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class ProjectDashboardService implements Resolve<any>
{
    projects: any[];
    widgets: any;

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient
    )
    {
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {

        return new Promise((resolve, reject) => {

            Promise.all([
                this.getProjects(),
                this.getWidgets()
            ]).then(
                () => {
                    // resolve();
                },
                reject
            );
        });
    }

    /**
     * Get projects
     *
     * @returns {Promise<any>}
     */
    getProjects(): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.get('api/project-dashboard-projects')
                .subscribe((response: any) => {
                    this.projects = response;
                    resolve(response);
                }, reject);
        });
    }

    /**
     * Get widgets
     *
     * @returns {Promise<any>}
     */
    getWidgets(): Promise<any>
    {
        return new Promise((resolve, reject) => {
                this.widgets = this.widgetsscr;
                resolve(this.widgetsscr);
        });
    }
     widgetsscr = {
        'widget80'      : {
            'title'    : 'Reporting Capitale Assuré par Fichier',
            'mainChart': [
                {
                    'name' : 'fichier 1',
                    'value': 120000
                },
                {
                    'name' : 'fichier 2',
                    'value': 170000
                },
                {
                    'name' : 'fichier 3',
                    'value': 280000
                },
                {
                    'name' : 'fichier 4',
                    'value': 250000
                },
                {
                    'name' : 'fichier 5',
                    'value': 150000
                }
            ]
        },
        'widget9'      : {
            'title'         : 'Reporting intérét partenaire par capital assuré',
            'ranges'        : {
                '2W': '2 Mois Avant'
            }, 
            'weeklySpent'   : {
                'title': 'fichier 1',
                'count': {
                    'TW': '36 000'
                },
            },
            'totalSpent'    : {
                'title': 'fichier 2',
                'count': {
                    'TW': '34 000'
                },
            },
            'remaining'     : {
                'title': 'fichier 3',
                'count': {
                    'TW': '89 000'
                },
            
            },
            'remaining1'     : {
                'title': 'fichier 4',
                'count': {
                    'TW': '100000'
                },
            
            },
            'remaining2'     : {
                'title': 'fichier 5',
                'count': {
                    'TW': '100000'
                },
            
            },
            'totalRemaining': {
                'title': 'Intérêt Total',
                'count': '2 400 000'
            },
            'totalBudget'   : {
                'title': 'Intérêt Total',
                'count': '1 400 000'
            }
        },
        'widget10'     : {
            'title': 'La Liste des fichiers importés',
            'table': {
                'columns': [
                    {
                        'title': 'Nom du fichier'
                    },
                    {
                        'title': 'Nombre d\'assurés totals'
                    },
                    {
                        'title': 'Nombre d\'assurés acceptés'
                    },
                    {
                        'title': 'Nombre d\'assuré rejettés'
                    },
                    {
                        'title': 'Date importation'
                    },
                    {
                        'title': 'Effectué par'
                    }
                ],
                'rows'   : [
                    [
                        {
                            'value'  : 'fichier 1',
                            'classes': 'primary',
                            'icon'   : ''
                        },
                        {
                            'value'  : '148',
                            'classes': 'text-bold',
                            'icon'   : ''
                        },
                        {
                            'value'  : '100',
                            'classes': '',
                            'icon'   : ''
                        },
                        {
                            'value'  : '48',
                            'classes': 'text-success',
                            'icon'   : 'trending_up'
                        },
                        {
                            'value'  : '10-12-2019',
                            'classes': '',
                            'icon'   : ''
                        },
                        {
                            'value'  : 'cheikh',
                            'classes': '',
                            'icon'   : ''
                        }
                    ],
                    [
                        {
                            'value'  : 'fichier 2',
                            'classes': 'green',
                            'icon'   : ''
                        },
                        {
                            'value'  : '148',
                            'classes': 'text-bold',
                            'icon'   : ''
                        },
                        {
                            'value'  : '100',
                            'classes': '',
                            'icon'   : ''
                        },
                        {
                            'value'  : '48',
                            'classes': 'text-success',
                            'icon'   : 'trending_up'
                        },
                        {
                            'value'  : '10-12-2019',
                            'classes': '',
                            'icon'   : ''
                        },
                        {
                            'value'  : 'cheikh',
                            'classes': '',
                            'icon'   : ''
                        }
                    ],
                    [
                        {
                            'value'  : 'fichier 3',
                            'classes': 'red',
                            'icon'   : ''
                        },
                        {
                            'value'  : '148',
                            'classes': 'text-bold',
                            'icon'   : ''
                        },
                        {
                            'value'  : '100',
                            'classes': '',
                            'icon'   : ''
                        },
                        {
                            'value'  : '48',
                            'classes': 'text-danger',
                            'icon'   : 'trending_down'
                        },
                        {
                            'value'  : '10-12-2019',
                            'classes': '',
                            'icon'   : ''
                        },
                        {
                            'value'  : 'cheikh',
                            'classes': '',
                            'icon'   : ''
                        }
                    ],
                    [
                        {
                            'value'  : 'fichier 4',
                            'classes': 'accent',
                            'icon'   : ''
                        },
                        {
                            'value'  : '148',
                            'classes': 'text-bold',
                            'icon'   : ''
                        },
                        {
                            'value'  : '100',
                            'classes': '',
                            'icon'   : ''
                        },
                        {
                            'value'  : '48',
                            'classes': 'text-info',
                            'icon'   : 'trending_flat'
                        },
                        {
                            'value'  : '10-12-2019',
                            'classes': '',
                            'icon'   : ''
                        },
                        {
                            'value'  : 'cheikh',
                            'classes': '',
                            'icon'   : ''
                        }
                    ],
                    [
                        {
                            'value'  : 'fichier 5',
                            'classes': 'orange',
                            'icon'   : ''
                        },
                        {
                            'value'  : '149',
                            'classes': 'text-bold',
                            'icon'   : ''
                        },
                        {
                            'value'  : '100',
                            'classes': '',
                            'icon'   : ''
                        },
                        {
                            'value'  : '49',
                            'classes': 'text-info',
                            'icon'   : 'trending_flat'
                        },
                        {
                            'value'  : '10-12-2019',
                            'classes': '',
                            'icon'   : ''
                        },
                        {
                            'value'  : 'cheikh',
                            'classes': '',
                            'icon'   : ''
                        }
                    ]
                ]
            }
        },
        'weatherWidget': {
            'locations'      : {
                'NewYork': {
                    'name'           : 'New York',
                    'icon'           : 'icon-rainy2',
                    'temp'           : {
                        'C': '22',
                        'F': '72'
                    },
                    'windSpeed'      : {
                        'KMH': 12,
                        'MPH': 7.5
                    },
                    'windDirection'  : 'NW',
                    'rainProbability': '98%',
                    'next3Days'      : [
                        {
                            'name': 'Sunday',
                            'icon': 'icon-rainy',
                            'temp': {
                                'C': '21',
                                'F': '70'
                            }
                        },
                        {
                            'name': 'Sunday',
                            'icon': 'icon-cloudy',
                            'temp': {
                                'C': '19',
                                'F': '66'
                            }
                        },
                        {
                            'name': 'Tuesday',
                            'icon': 'icon-windy3',
                            'temp': {
                                'C': '24',
                                'F': '75'
                            }
                        }
                    ]
                }
            },
            'currentLocation': 'NewYork',
            'tempUnit'       : 'C',
            'speedUnit'      : 'KMH'
        },
        'widget6'      : {
            'title'      : 'Nombres de demandes/Assurance',
            'ranges'     : {
                'TW': 'mois en cours',
                'LW': 'mois Dernier',
                '2W': '2 mois Avant'
            },
            'mainChart'  : {
                'TW': [
                    {
                        'name' : 'Sénégal',
                        'value': 15
                    },
                    {
                        'name' : 'Mali',
                        'value': 20
                    },
                    {
                        'name' : 'Cote d\'Ivoire',
                        'value': 38
                    },
                    {
                        'name' : 'Mauritanie',
                        'value': 27
                    }
                ],
                'LW': [
                    {
                        'name' : 'Sénégal',
                        'value': 19
                    },
                    {
                        'name' : 'Mali',
                        'value': 16
                    },
                    {
                        'name' : 'API',
                        'value': 42
                    },
                    {
                        'name' : 'Mauritanie',
                        'value': 23
                    }
                ],
                '2W': [
                    {
                        'name' : 'Sénégal',
                        'value': 18
                    },
                    {
                        'name' : 'Mali',
                        'value': 17
                    },
                    {
                        'name' : 'Cote d\'Ivoire',
                        'value': 40
                    },
                    {
                        'name' : 'Mauritanie',
                        'value': 25
                    }
                ]
            },
            'footerLeft' : {
                'title': 'Capital Assuré Total',
                'count': {
                    '2W': 487,
                    'LW': 526,
                    'TW': 594
                }
            },
            'footerRight': {
                //'title': 'Tasks Completed',
                'count': {
                    '2W': 193,
                    'LW': 260,
                    'TW': 287000
                }
            }
        },
    };
}
