import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';
import * as Excel from 'exceljs/dist/exceljs.min.js';
import * as FileSaver from 'file-saver';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
export class Style{
  color:string="black";
  bgColor:string="white";
  width:number=25;
  border:string="thin";
  font:{
    name: 'Calibri', 
    family: 4, 
    size: 11, 
    bold: false, 
    strike: true
  }

}
@Injectable()
export class ExportService {
  [x: string]: any;
  arrayBuffer: any;
  file: File;
  constructor() {
  }

  static toExportFileName(excelFileName: string): string {
    return `${excelFileName}_.xlsx`;
  }
  public exportAsExcelFile1(json: any[], excelFileName: string,headersArray: any[]=null,isDisplayHeader:boolean=true,globalStyles:Style[]=null,otherStyles:any=null): void {
    //Excel Title, Header, Data
   // const EXCEL_TYPE:string = 'application/vnd.openxmlformatsofficedocument.spreadsheetml.sheet;charset=UTF-8';

   //  const EXCEL_EXTENSION = '.xlsx';
    const header = headersArray;
    const data = json;
    //Create workbook and worksheet
    //let workbook = new Workbook();
    var workbook = new Excel.Workbook();


    let worksheet = workbook.addWorksheet(excelFileName);
    //Add Header Row
    if(isDisplayHeader){
      let headerRow = worksheet.addRow(header);
      // Cell Style : Fill and Border
      headerRow.eachCell((cell, number) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFFFFF00' },
          bgColor: { argb: 'FF0000FF' }
        }
        cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
      })
    }

    // Add Data and Conditional Formatting
    data.forEach((element,index) =>{
      let eachRow = [];
      headersArray.forEach((headers) =>{
      eachRow.push(element[headers])
      })
     
      if (element.isDeleted === "Y") {
        let deletedRow = worksheet.addRow(eachRow);
        deletedRow.eachCell((cell, number) => {
          cell.font = { name: 'Calibri', family: 4, size: 11, bold: true, strike: true };
        })
      } else {
       // eachRow.forEach
        let dataRow=worksheet.addRow(eachRow);
        // if(otherStyles[index]!=null){
        //   dataRow.eachCell((cell, indexCell)=>{
        //    cell.font=otherStyles[index].font
        //    if(otherStyles[index].color)
        //     cell.fill = { type: 'pattern',
        //     pattern: 'solid',fgColor:{ argb:otherStyles[index].color  || 'ffffff'},

        //     bgColor:{ argb:otherStyles[index].bgColor || '000000'}
          
        //   };

        //     if(otherStyles[index].bgColor)
        //     cell.fill .bgColor={ argb:otherStyles[index].bgColor};
        //    });
         
        // }
       // cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }

      }
    });

// apply a global styles
    globalStyles.forEach((columnStyle,index)=>{
      worksheet.getColumn(index+1).width = columnStyle.width;
     worksheet.getColumn(index+1).border=columnStyle.border;
    });
    

    worksheet.addRow([]);
    //Generate Excel File with given name
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: EXCEL_TYPE });
      FileSaver(blob, excelFileName + EXCEL_EXTENSION);
    })
  }
  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    XLSX.writeFile(workbook, ExportService.toExportFileName(excelFileName));
  }
  preFormatLoanInfo(data) {
    let cp = Object.assign([], data);
    cp = JSON.stringify(cp);
    return JSON.parse(cp);
  }
  saveAsPdf(jsonData, infoData = { fileName: "fichierpdf", userName: "inconnue" }, critereRecherche?) {
    if (critereRecherche) {
      let rows = [];
      let col = [];
      for (var index in jsonData) {
        if (Number(index) == 0) {
          col.push(Object.keys(jsonData[index]));
        }
        var resultArray = Object.keys(jsonData[index]).map(function (element) {
          let objArray = jsonData[index][element];
          return objArray;
        });
        rows.push(resultArray);
      }
      let pdfOptions = {
        orientation: 'l',
        unit: 'mm',
        format: 'a4',
        putOnlyUsedFonts: true,
        precision: 2
      }
      let headStyle = {
        fillColor: [145, 87, 30],
        fontSize: 12
      }
      let doc;
      let width = doc.internal.pageSize.getWidth();
      let height = doc.internal.pageSize.getHeight();
      const finalY = doc.previousAutoTable.finalY;
      let today = new Date().getDay() + "/" + new Date().getMonth() + "/" + new Date().getFullYear();
      doc.addImage(this.getBase64Image(), "png", 10, 10, 60, 12, 'logo', 'FAST', 0);
      doc.text("Critere de recherche", width / 3, 30);
      doc.text(infoData.fileName, width / 3, 57);
      doc.setFont("times");
      doc.setFontType("italic");
      doc.setFontSize(12);
      doc.text("Prénom Souscripteur: " + critereRecherche.souscription.prenom, 15, 37);
      doc.text("Nom Souscripteur: " + critereRecherche.souscription.nom, 100, 37);
      doc.text("Prénom Assuré: " + critereRecherche.assure.prenom, 190, 37);
      doc.text("Nom Assuré: " + critereRecherche.assure.nom, 15, 44);
      doc.text("Prénom Bénéficiaire: " + critereRecherche.beneficaire.prenom, 100, 44);
      doc.text("Nom Bénéficiaire: " + critereRecherche.beneficaire.nom, 190, 44);
      doc.text("Durée: " + critereRecherche.duree.displayMe, 15, 51);
      doc.text("Prix: " + critereRecherche.duree.price, 15, 51);
      doc.autoTable(
        {
          head: col,
          body: rows,
          margin: { top: 61 },
          headStyles: headStyle,
          showHead: 'firstPage',
          rowPageBreak: 'avoid'
        });
      doc.setFont("times");
      doc.setFontType("italic");
      doc.setFontSize(10);
      doc.text(infoData.fileName + " du " + today, width - 120, 15);
      //pied de page  
      let colF = [[infoData.fileName, "généré par " + infoData.userName, "le " + today]];
      var rowsF = [];
      doc.autoTable({
        head: colF,
        body: rowsF,
        startY: height - 28.1,
        headStyles: { fillColor: [140, 80, 30], fontSize: 8 },
        pageBreak: 'avoid',
        margin: { horizontal: 20 },
        bodyStyles: { valign: 'top' },
        styles: { overflow: 'linebreak', cellWidth: 'wrap' },
        columnStyles: { cellWidth: 'auto' }
      });
      //Sauvegarde pdf
      doc.save(infoData.fileName + '_' + new Date().toISOString() + '.pdf');
    } else {
      this.saveAsPdfTableOnly(jsonData, infoData);
    }
  }
  saveAsPdfTableOnly(jsonData, infoData = { fileName: "fichierpdf", userName: "inconnue" }) {
    let rows = [];
    let col = [];
    for (var index in jsonData) {
      if (Number(index) == 0) {
        col.push(Object.keys(jsonData[index]));
      }
      var resultArray = Object.keys(jsonData[index]).map(function (element) {
        let objArray = jsonData[index][element];
        return objArray;
      });
      rows.push(resultArray);
    }
    let pdfOptions = {
      orientation: 'l',
      unit: 'mm',
      format: 'a4',
      putOnlyUsedFonts: true,
      precision: 2
    }
    let headStyle = {
      fillColor: [145, 87, 30],
      fontSize: 12
    }
    let doc;
    // new jsPDF(pdfOptions)
    let width = doc.internal.pageSize.getWidth();
    let height = doc.internal.pageSize.getHeight();
    const finalY = doc.previousAutoTable.finalY;
    let today = new Date().getDay() + "/" + new Date().getMonth() + "/" + new Date().getFullYear();
    // addImage(imageData, format, x, y, width, height, alias, compression, rotation)
    doc.addImage(this.getBase64Image(), "png", 10, 10, 60, 12, 'logo', 'FAST', 0);
    // doc.text(infoData.fileName+" "+today,200,14);
    //Title du tableau
    doc.text(infoData.fileName, width / 3, 30);
    doc.autoTable(
      {
        head: col,
        body: rows,
        margin: { top: 32 },
        headStyles: headStyle,
        showHead: 'firstPage',
        rowPageBreak: 'avoid'
      });
    doc.setFont("times");
    doc.setFontType("italic");
    doc.setFontSize(10);
    doc.text(infoData.fileName + " du " + today, width - 100, 15);
    //pied de page  
    let colF = [[infoData.fileName, "généré par " + infoData.userName, "le " + today]];
    var rowsF = [];
    doc.autoTable({
      head: colF,
      body: rowsF,
      startY: height - 28,
      headStyles: { fillColor: [0, 0, 0], fontSize: 8 },
      pageBreak: 'avoid',
      margin: { horizontal: 7 },
      bodyStyles: { valign: 'top' },
      styles: { overflow: 'linebreak', cellWidth: 'wrap' },
      columnStyles: { cellWidth: 'auto' }
    });
    //Sauvegarde pdf
    doc.save(infoData.fileName + '_' + new Date().getTime() + '.pdf');
  }
  readDataFromExcel(event: any) {
    this.file = event.target.files[0];
    let fileReader = new FileReader();
    fileReader.onload = (e) => {
      this.arrayBuffer = fileReader.result;
      var data = new Uint8Array(this.arrayBuffer);
      var arr = new Array();
      for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join("");
      var workbook = XLSX.read(bstr, { type: "binary" });
      var first_sheet_name = workbook.SheetNames[0];
      var worksheet = workbook.Sheets[first_sheet_name];
      let files = XLSX.utils.sheet_to_json(worksheet, { raw: true });
    }
    fileReader.readAsArrayBuffer(this.file);
  }
  getBase64Image() {
    var img = new Image(); //  Constructeur HTML5
    img.src = 'assets/img/logo.png';
    // We create a HTML canvas object that will create a 2d image
    var canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    var ctx = canvas.getContext("2d");   // This will draw image    
    ctx.drawImage(img, 0, 0);
    // Convert the drawn image to Data URL
    var dataURL = canvas.toDataURL("image/png");
    return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
  }
}