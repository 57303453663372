import { MatPaginatorIntl } from '@angular/material';

export function CustomPaginator() {
  const customPaginatorIntl = new MatPaginatorIntl();

  customPaginatorIntl.itemsPerPageLabel = 'Elements par page';
  customPaginatorIntl.nextPageLabel="Page suivante";
  customPaginatorIntl.previousPageLabel="Page précédente";
  customPaginatorIntl.lastPageLabel="Dernière page";
  customPaginatorIntl.firstPageLabel="Première page";
  return customPaginatorIntl;
}