import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
  /* {
    id: 'parametrage',
    title: 'Parametrages',
    translate: 'NAV.APPLICATIONS',
    type: 'group',
    icon: 'settings_applications',
    children: [
      {
        id: 'Agence',
        title: '  ',
        translate: 'Agence',
        type: 'collapsable',
        icon: 'domain',

        children: [
          {
            id: 'Agences',
            title: 'Agences',
            translate: 'Agences',
            type: 'item',
            icon: 'domain',
            url: 'administration/agences'
          },
          {
            id: 'Clients',
            title: 'Clients',
            translate: 'Clients',
            type: 'item',
            icon: 'settings',
            url: 'administration/clients'
          },
          {
            id: 'Providers',
            title: 'Providers',
            translate: 'Providers',
            type: 'item',
            icon: 'settings',
            url: 'administration/providers'
          },
          {
            id: 'Type Clients',
            title: 'Type Clients',
            translate: 'Type Clients',
            type: 'item',
            icon: 'perm_data_setting',
            url: 'administration/typeClients'
          }
        ]
      }
    ]
  }*/
];
