
import { Component, Inject, ViewEncapsulation, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatColors } from '@fuse/mat-colors';
import { ThemePalette } from '@angular/material/core';
import { ExportService } from '../../../_services/export.service';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import html2canvas from 'html2canvas'; 
import { DetailFormComponent } from '../../formulaire/formulaire-details/formulaire-details.component';
import { MatDialog } from '@angular/material/dialog';
@Component({
    selector: 'upload-pdf',
    templateUrl: './upload-pdf.component.html',
    styleUrls: ['./upload-pdf.component.scss'],
    providers:[ExportService],
    encapsulation: ViewEncapsulation.None
})

export class UploadPdfComponent implements OnInit,AfterViewInit  {
  dialogRef: any;
    @ViewChild("picker", { static: false }) picker: any;
    action: string;
    dialogTitle: string;
    presetColors = MatColors.presets;
    public color: ThemePalette = 'accent';
    rowOpened: string;
    userConnecte = null;
    detailsPdf: any;
    loaderVisible = false;
    loadData: boolean = false;
    codeRecu: any; //code recu de l'api lors de la connexion 
    codeEnvoye: number=1716; //code envoye par notre menu
    hasGenererAppel: boolean;
    typeProduit;
     dates = new Date();
     month;
     year;
     mois = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];
    constructor(
        public matDialogRef: MatDialogRef<UploadPdfComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _matDialog: MatDialog
    ) {
        this.action = _data.action;
        this.detailsPdf = _data.pdf;
        if (this.action === 'upload') {
            this.dialogTitle = 'Apercu du fichier appel à cotisation';
            this.detailsPdf = _data.pdf;
        }
        if(this.detailsPdf.produit.typeProduit){
          this.typeProduit = this.detailsPdf.produit.typeProduit.libelle;
        }
        this.month=this.mois[this.dates.getMonth()],
        this.year = this.dates.getFullYear();
    }
    ngOnInit(): void {
        this.userConnecte = JSON.parse(localStorage.getItem('inclusiveGuarantee'));
        this.codeRecu = JSON.parse(localStorage.getItem('codeRecu'));
        this.checkCode();
    }
    // cette fonction permet de gerer les autorisations
    checkCode(){
      this.hasGenererAppel = this.codeRecu.tabIDS.indexOf('0'+(this.codeEnvoye+1)) != -1;
    }
    // cette fonction permet de ne pas afficher le modal et d'appeler directement la fonction captureScreen
    ngAfterViewInit():void{
      this.captureScreen();
    }
    // cette fonction permet de prendre une page html de le traduire en pdf
    public captureScreen(){
      var data = document.getElementById('contentToConvert');  
      html2canvas(data).then(canvas =>{  
        this.loadData = true; 
        var imgWidth = 210;   
        var pageHeight = 380;    
        var imgHeight = canvas.height * imgWidth / canvas.width;  
        var heightLeft = imgHeight;  
    
        const contentDataURL = canvas.toDataURL('image/png')  
        let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF  
        var position = 0;  
        pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight) 
        pdf.save('Appel-à-Cotisation.pdf'); // Generated PDF 
     //   pdf.
      });
      this.loadData = false; 
      // if(data){
      //   this.loadData = false;
      this.matDialogRef.close(); 
      // }
     
    }  
 
}
