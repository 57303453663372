import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';
import { fuseConfig } from 'app/fuse-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { SampleModule } from 'app/main/sample/sample.module';
import { MaterialUiModule } from './material-ui/material-ui.module';
import { BreadcrumbService } from './_services/breadcrumb.service';
import { ProjectDashboardService } from './administration/dashboard-homogene/project.service';
import { Error404Module } from './404/error-404.module';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import {HTTP_INTERCEPTORS } from '@angular/common/http';
import {TokenExpiredInterceptor } from './_interceptors/interceptor';
import {MatDatepickerModule, MatInputModule,MatNativeDateModule, MatPaginatorIntl} from '@angular/material';
import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';
import { FakeDbService } from 'app/fake-db/fake-db.service';
import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import {Ng2TelInputModule} from 'ng2-tel-input';
import { PdfModule } from './pdf/pdf.module';
import { UploadPdfComponent } from './administration/fichier-assure/upload-pdf/upload-pdf.component';
import { GenererAppelRemboursementComponent } from './administration/sinistre/sinistre-importer/appel-remboursement/appel-remboursement.component';
import { CustomPaginator } from './CustomPaginatorConfiguration';
const appRoutes: Routes = [
  {
    path: 'administration',
    loadChildren: './administration/administration.module#AdministrationModule'
  },
  {
    path: 'login',
    loadChildren: './mylogin/mylogin.module#MyloginModule'
  },
  {
    path: 'forgot-password',
    loadChildren: './forgot-password/forgot-password.module#ForgotPasswordModule'
  },
  {
    path: 'pdf',
    loadChildren: './pdf/pdf.module#PdfModule'
  },

  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: 'error-404'
  }
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes),
    MatDatepickerModule, MatInputModule,MatNativeDateModule,
    TranslateModule.forRoot(),
    SweetAlert2Module.forRoot(),
    Ng2TelInputModule,
    TranslateModule.forRoot(),
    InMemoryWebApiModule.forRoot(FakeDbService, {
        delay             : 0,
        passThruUnknownUrl: true
    }),

    // Material moment date module
    MatMomentDateModule,

    // Material
    MatButtonModule,
    MatIconModule,
    HttpClientModule,

    // Fuse modules
    FuseModule.forRoot(fuseConfig),
    FuseProgressBarModule,
    FuseSharedModule,
    FuseSidebarModule,
    FuseThemeOptionsModule,

    // App modules
    LayoutModule,
    SampleModule,
  

    Error404Module, // page erreur 404
    PdfModule,
    // Dossier Des imports des composants relatifs à Material Design
    MaterialUiModule

    // TestModule,
  ],
  providers: [
    BreadcrumbService,
    ProjectDashboardService,
    MatIconRegistry,
    {provide: MAT_DATE_LOCALE, useValue: 'fr-FR'},
    { provide: HTTP_INTERCEPTORS, useClass: TokenExpiredInterceptor, multi: true },
    { provide: MatPaginatorIntl, useValue: CustomPaginator() }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
