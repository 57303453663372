import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {
  
  constructor() { }

  private subject = new Subject<any>();
  private test = new BehaviorSubject<any>({});
  testSync = this.test.asObservable();

  sendMessage(cheminActuel: string) { console.log("=======> BREADCRUM SERVICE (envoi): "+cheminActuel)
      this.test.next({ text: cheminActuel });
  }

  clearMessages() {
      this.subject.next();
  }

  getMessage(){ console.log('Bredcrumb (getMessage):')
      return this.subject.asObservable();
  }
}
