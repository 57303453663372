export const environment = {
  production: false,
  hmr: false,
   // apiURL: 'http://144.217.252.163:8080/recolte-adi/api/',
    apiURL: 'https://recolte-adi-bo.inclusiveguarantee.tech/api/',
    apiURLPreuve: 'https://recolte-adi-bo.inclusiveguarantee.tech/api/download-preuve?fileName=',
    apiURL1: 'https://recolte-adi-bo.inclusiveguarantee.tech/apit/',
     max: 1000000,
     offset:0,
     appUrlAppelCotisation:"https://recolte-adi-bo.inclusiveguarantee.tech/",
     appUrlPreuve:"https://recolte-adi-bo.inclusiveguarantee.tech/",
     apiUrlContrat: 'http://144.217.252.163:8081/contrat/generer.php?id=',
};
