import { Component, ViewEncapsulation } from '@angular/core';
import { UploadPdfComponent } from '../administration/fichier-assure/upload-pdf/upload-pdf.component';
import { RechercheService } from '../_services/recherche.service';
import { MatDialog } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { FuseConfigService } from '@fuse/services/config.service';
import { GenererAppelRemboursementComponent } from 'app/administration/sinistre/sinistre-importer/appel-remboursement/appel-remboursement.component';
import * as moment from 'moment';
import { ExportService } from 'app/_services/export.service';

@Component({
    selector     : 'pdf',
    templateUrl  : './pdf.component.html',
    styleUrls    : ['./pdf.component.scss'],
    providers:[ExportService],
    encapsulation: ViewEncapsulation.None
})
export class PdfComponent
{
    dialogRef: any;
    donneesAppels:any;
    donneesAppelsRemboursements:any;
    donneesAppelsRemboursementsSinistres:any;
    data:any;
    uuId:any;
    uuIdremboursement:any;
  constructor(private _fuseConfigService: FuseConfigService,
    private _matDialog: MatDialog,
    private rechercheService: RechercheService,
    private route : ActivatedRoute,
    private exportService: ExportService
  ) {
    this._fuseConfigService.config = {
      layout: {
          navbar: {
              hidden: true
          },
          toolbar: {
              hidden: true
          },
          footer: {
              hidden: true
          },
          sidepanel: {
              hidden: true
          }
      }
  };
    this.uuId = this.route.snapshot.params['id'];
    this.uuIdremboursement = this.route.snapshot.params['uuid'];
    if (this.uuId != undefined && this.uuId != null && this.uuId != 'null' && this.uuId != '') {
    this.rechercheService.rechercheAppelCotisation(this.uuId).
    subscribe(response => {
      if (response['responseCode'] == 200) {
        this.donneesAppels = response['data'];
        this.uploadPdf(this.donneesAppels);
      } else {
      }
    });
    }else if(this.uuIdremboursement != undefined && this.uuIdremboursement != null  && this.uuIdremboursement != 'null' && this.uuIdremboursement != '') {
      this.rechercheService.rechercheAppelRemboursement(this.uuIdremboursement).
      subscribe(response => {
        if (response['responseCode'] == 200) {
          this.donneesAppelsRemboursements = response['data'];
          this.donneesAppelsRemboursementsSinistres = response['data']['aRembourser'];
          this.appelRemboursement(this.donneesAppelsRemboursements);
          this.appelRemboursementSinistre(this.donneesAppelsRemboursementsSinistres);
        } else {
        }
      });
    }
}
  uploadPdf(details): void {
    details.type='pdf';
    this.dialogRef = this._matDialog.open(UploadPdfComponent, {
      panelClass: 'event-form-dialog',
      width: '70%',
      height: '100%',
      data: {
        action: 'upload',
        pdf:details
      }
    });
    this.dialogRef.hidden=true;
  }
  appelRemboursement(details): void {
    this.dialogRef = this._matDialog.open(GenererAppelRemboursementComponent, {
      panelClass: 'event-form-dialog',
      width: '50%',
      height: '100%',
      data: {
        action: 'upload',
        pdf:details
      }
    });
  }
  appelRemboursementSinistre(details): void {
      let nom = "Liste des sinistres";
      let value = [];
      if(details.length!=0){
      for(let val of details){
        let donnees = { prenom:val.assure.prenom ,nom:val.assure.nom , dateNaissance: moment(val.assure.dateNaissance).format('DD/MM/YYYY'),telephone:val.assure.telephone,numeroPiece:val.assure.numeroPiece, dateSouscription: moment(val.dateSouscription).format('DD/MM/YYYY'), datePret: moment(val.datePret).format('DD/MM/YYYY'), dureePret: val.dureePret, montantPret: val.montantPret,createdAt: moment(val.createdAt).format('DD/MM/YYYY') }
          value.push(donnees);
      }
    }
        if (value.length != 0) {
            var col = ['Prénom', 'Nom', 'Date naissance', 'Téléphone', 'Numéro Piéce', 'Date Souscription', 'Date prêt', 'Durée prêt', 'Montant prêt','Date création'];
            let tabFileBody = ['prenom', 'nom', 'dateNaissance', 'telephone', 'numeroPiece', 'dateSouscription', 'datePret','dureePret','montantPret','createdAt'];
            var rows = [];
            var itemCurrent;
            for (var item of value) {
              itemCurrent = item;
              let tabField = [];
              let elementKeys = Object.keys(item);
              let i = 0;
              for (let field of tabFileBody) {
                for (let element of elementKeys) {
                  if (element== field) {
                     tabField.push({ [col[i]]: (itemCurrent[field] || '') });
                  }
                }
                i++;
              }
              rows.push(Object.assign({}, ...tabField));
            }
            this.exportService.exportAsExcelFile(this.exportService.preFormatLoanInfo(rows), nom);
          
        } 
  }
}
