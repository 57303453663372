
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class TokenExpiredInterceptor implements HttpInterceptor {
  constructor() {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.includes("/login") || request.url.includes("apit") || request.url.includes("forget/password") || request.url.includes("get-appel-cotisation-by-uuid") || request.url.includes("reset/password")){
      request = request.clone({
       });
    }
    else if(request.url.includes("import-parametre-pluvio") || request.url.includes("import-donnee-pluvio") || request.url.includes("file-import") || request.url.includes("valider-import") || request.url.includes("add-justificatif") || request.url.includes("importer-preuve") || request.url.includes("importer-preuve-remboursement")) {
      
         let token = JSON.parse(localStorage.getItem('inclusiveGuarantee'));
          request = request.clone({
              setHeaders: {
                Authorization: token ? `Bearer ${token.id_token}`: '',
                      'Access-Control-Allow-Origin': '*' ,
              }
            });
   }else{
     let token = JSON.parse(localStorage.getItem('inclusiveGuarantee'));
     request = request.clone({
         setHeaders: {
           Authorization: token ? `Bearer ${token.id_token}`: '',
            "Content-Type":  "application/json",
                'Access-Control-Allow-Origin': '*' ,
         }
       });
   }
   return next.handle(request);
 }
Réduire




}
